a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-left {
  margin-left: -3% !important;
}

.navbar-right {
  margin-left: auto !important;
}

.nav-item {
  margin-right: 5px !important;
}

.go-to-search-nav {
  height: 100%;
  margin-left: -40px;
}

.search-input {
  margin-top: 10px;
  width:50%;
}

.search-button {
  background-color: #4D5061;
  border: 0;
  color: #FFFFFF;
  padding: 2.5px 15px;
  margin-left: 12px;
  margin-right: 20px;
}

.search-button:hover {
  background-color: #65687c;
}
