@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css");
@import url("https://use.fontawesome.com/releases/v5.2.0/css/solid.css");
@import url("https://use.fontawesome.com/releases/v5.2.0/css/fontawesome.css"); /* Provide sufficient contrast against white background */

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification\
for details on configuring this project to bundle and minify static web assets. */

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f8f8;
}

table.tasks td, table.tasks th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 150px;
}

table th a {
  text-transform: uppercase;
  font-size: smaller;
}

.bg-primary {
  background-color: #99C20E !important;
}

.alert-success {
  color: #99C20E !important;
  background-color: white;
  border-color: #99C20E;
}

.bg-secondary {
  background-color: #ccc !important;
}

a {
  color: #99C20E;
}

a:hover {
  color: #868686;
}

form {
  margin: 0;
  padding: 1.25rem;
}

.main-content {
  background-color: white;
  padding: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #eee;
}

.sub-menu {
  margin: 20px 0px;
}

table.form-check-input span label {
  vertical-align: middle;
  font-size: initial;
}

table.form-check-input span input {
  margin-right: 4px;
}

table.form-check-input span label {
  margin-right: 10px;
}

.logo {
  font-size: 3em;
  line-height: 1em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #99C20E;
}

.logo .title:before {
  content: '\2713';
  color: #868686;
}

.logo .title em:before {
  content: 'Correct';
  font-style: normal;
}

.logo .title em:after {
  content: 'Track';
  font-weight: lighter;
  font-style: normal;
  color: #868686;
}

.navbar-brand .logo {
  color: white;
  font-size: 2em;
}

.navbar-brand .logo .title:before {
  color: rgba(255,255,255, .5);
}

.navbar-brand .logo .title em:after {
  color: rgba(0,0,0,.3);
}

.buglist_popup {
  z-index: 111;
  border: 1px solid #ddd;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #f8f8f8;
  color: black;
  display: none;
  /* max-width: 500; */ /* Corey, 2013, says: does not work in ie, firefox, causes truncation in Chrome */
  margin: 0;
  overflow: hidden;
}

.page-link {
  color: #99C20E;
}

.page-item.active .page-link {
  color: white;
  background-color: #99c20e;
  border-color: #99C20E;
}

.page-link:hover {
  color: #76960c;
}

.page-link i {
  height: 20px;
}

#posts_table {
  width: 100%;
}

#posts_table .cmt {
  border: 1px solid #ddd;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

#posts_table span.pst {
  font-weight: bold;
  padding-top: 0px;
}

#posts_table img {
}

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-text {
  color: white;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(0,0,0,.3);
  font-weight: bold;
}

.dashboard-item .card-body{
  padding: 0;
}
.dashboard-item iframe {
  width: 100%;
  height: 300px;
}
.bugd.flag span, .bugd.seen span {
  margin: auto;
  display: block;
  width: 16px;
  height: 16px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.wht {
  background: none;
  border: 1px solid #cccccc;
}

.red {
  background: red;
  border: 1px solid red;
}

.grn {
  background: #99C20E;
  border: 1px solid #99C20E;
}

/* for "new" column */
.new {
  background: #868686;
  border: 1px solid #868686;
}

.old {
  border: 1px solid #cccccc;
}

@media only screen and (max-width: 600px) {
  .bugf {
    display: none;
  }
}

.environment-label {
  text-align: center;
  padding: 6px;
  font-weight: bold;
}

@page	{
  size: auto;
}

.risk-level-high {
  background-color: #ee8011;
  color: white;
}
.risk-level-low {
  background-color: #99C20E;
  color: white;
}
.risk-level-medium {
  background-color: #eeee11;
}
.risk-level-very-high {
  background-color: #ee1111;
  color: white;
}
tr[data-href]:hover td:not(.risk-level-low, .risk-level-medium, .risk-level-very-high, .risk-level-high) {
  background-color: #eafab7;
  cursor: pointer;
}
table.nowrap td {
  white-space: nowrap;
}
table td.changed {
  background-color: #eafab7;
  font-weight: bold;
}

.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 95%;
  min-width: 1140px;
}

#posts_table {
  width: 100%;
}

#posts_table .cmt {
  border: 1px solid #ddd;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

#posts_table span.pst {
  font-weight: bold;
  padding-top: 0px;
}

#printTaskDataTable td {
  border: 1px solid;
  border-width: 1px;
}

.select-query-columns {
  width: 300px;
  height: 200px;
}

.select-query-option:hover {
  background-color: lightgrey;
}

.query-move-column-button {
  margin-bottom: 10px;
}
.resource-admin-buttons {
  margin-left: auto;
  margin-right: 0;
}

.resource-filter :hover {
  cursor: pointer;
  background-color: #dddddd;
}

.resource-filter-selected {
  background-color: #dddddd;
}

.table-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.left-aligned-text {
  display:flex;
  flex-grow: 3;
  margin-left: 0;
  margin-right: auto;
  justify-content: flex-start;
}

.right-aligned-text {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  justify-content: flex-end;
}

.pdf__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.pdf__container__load {
  margin-top: 1em;
  color: white;
}

.pdf__container__document {
  margin: 1em 0;
}

.pdf__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.pdf__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}
